import React from 'react'
import Layout from "../components/layout"
import style from '../styles/sites.module.css'
import Infocard from '../components/infocard'
import { graphql} from 'gatsby'
import SEO from '../components/seo'

function bodyHair({data}) {
    return (
        <Layout >
          <SEO title = "Body & Hair"
          description ="Gönne deinem Körper eine Luxuspflege! Alle meine Produkte kannst du in einem kostenlosen und unverbindlichen Termin kennenlernen!"/>
            <h1>Von Kopf bis Fuß gepflegt!</h1>
            <p>
            Exquisite Ingredienzen aus der Natur 
            treffen auf biotechnologisch hochentwickelte 
            Wirkstoffe für jeden Anwendungsbereich und jedes Hautbild. 
            Gönne deinem Körper eine Luxuspflege! 
            Alle  Produkte kannst du in einem kostenlosen und unverbindlichen 
            Termin kennenlernen! 
            </p>
          
            <section className={style.services}>
          {data.bodyHair.nodes.map((item) => {
            return(
              <Infocard data = {item} 
              key = {item.id}/>        
            )
          })}
       </section>

     
        </Layout>
    )
}
export const query = graphql`
  {
    bodyHair: allContentfulBodyHair(sort: {order: ASC, fields: order}) {
      nodes {
        text {
          childMarkdownRemark{
            html
          }
        }
        title
        id
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

export default bodyHair
